import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Home';
import Gallery from './Gallery';
import Blog from './Blog';
import Locations from './Locations';
import About from './About';
import ContactUs from './ContactUs';
import ResidentalRemoval from './ResidentalRemoval';
import Sacrap from './Sacrap';
import Office from './OfficeRemoval'
import Construction from './ConstructionRemoval'
import EWaste from './EWaste'
import Furniture from './FurnitureRemoval'
import Custruction from './ConstructionRemoval'
import {Helmet} from 'react-helmet';
import ReactGA from "react-ga4";

function App() {
  const TRACKING_ID = 'G-Y1KE9RW3J7';
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: "/ContactUs", title: "Junk Marina-Take All My Junk" });
  return (
    <BrowserRouter>
    <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y1KE9RW3J7">
</script>
<script>
{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y1KE9RW3J7');
  `}
</script>
      <title>Junk Marina-Take All My Junk</title>
      <link rel="icon" href="/favicon.ico" />
    <meta name="description" content="Dubai's No 1 Free junk removal service.Free Junk Remover will take your Unwanted items, any kind of Junk. Fast and easy junk removal services in Dubai. Expert junk removal team ready to declutter your space. Hassle-free removal of furniture, appliances, and more. Get a clean, spacious home or office today!"/>
<meta name='keywords' content='junk dubai, 
take my junk dubai, 
800 junk dubai, 
remove my junk dubai, 
take my junk dubai contact number, 
800 junk dubai number, 
sell my junk dubai, 
800 got junk dubai reviews, 
remove junk dubai, 
take my junk dubai photos, 
take my junk dubai municipality, 
dubai scrap auction, 
dubai scrap price, 
junkyard dubai, 
dubai junk cars, 
junk food available in dubai, 
what not to buy in dubai, 
what is worth buying in dubai, 
where to buy cheap things in dubai, 
dubai junk yard, 
junk busters dubai, 
scrap buyers dubai, 
dubai scrap business, 
trash bin dubai, 
garbage bin dubai, 
dubai scrap ban, 
garbage bag dubai, 
buy junk dubai, 
buy my junk dubai, 
best junk food in dubai, 
junk buyers in dubai, 
dubai junkyard cars, 
junk collection dubai, 
junk collection dubai free, 
dubai salvage cars for sale, 
rubbish collection dubai, 
scrap company dubai, 
trash collection dubai, 
dubai scrap car parts, 
garbage chute dubai, 
garbage company dubai, 
collect my junk dubai, 
clean my junk dubai, 
dubai municipality junk collection, 
free junk collection dubai, 
dubai car junk yard, , 
garbage disposal dubai, 
dubai rubbish dump, 
rubbish disposal dubai, 
dubai scrap dealer in india, 
washing machine price in dubai dubizzle, 
junk dubai free, 
distributors in dubai, 
dubai scrap export ban, 
dubai scrap exporters, 
junk fitness dubai, 
junk food dubai, 
scrap from dubai, 
salvage ferrari dubai, 
cheap goods in dubai, 
free junk removal dubai, 
junk furniture removal dubai, 
where to buy cheap furniture in dubai, 
fat loss treatment in dubai, 
where to buy cheap gadgets in dubai, 
scrap gold dubai, 
unwanted gifts dubai, 
get my junk dubai, 
junk gym dubai, 
gac dealer in dubai, 
g.i. junk removal, 	
home junk removal dubai, 	
trash in dubai, 	
garbage in dubai, 	
dubai trash island, 	
garbage incinerator dubai, 	
trash in dubai today, 
junk removal in dubai, 
junk yard in dubai, 
junk cars in dubai, 
junk shop in dubai, 
junk food in dubai, 
just junk locations, 
take my junk dubai reviews, 
junk kouture dubai, 
komatsu dealer in dubai, 	
k junk, 	
dubai scrap market, 
scrap metal dubai, 	
dubai trash mountain, 
dubai rubbish mountain, 
dubai scrap market cars, 
dubai scrap market suppliers, 
dubai scrap market area, 
dubai municipality junk removal, 
my junk dubai, 
junk to go removal, 
how can i get dubai fine discount, 
junk pickup dubai'/>
    </Helmet>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/blog' element={<Blog/>}/>
      <Route path='/locations' element={<Locations/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/ContactUs' element={<ContactUs/>}/>
      <Route path='/ResidentialRemoval' element={<ResidentalRemoval/>}/>
      <Route path='/ConstructionRemoval' element={<Custruction/>}/>
      <Route path='/Scrap' element={<Sacrap/>}/>
      <Route path='/officeRemoval' element={<Office/>}/>
      <Route path='/ContactUs' element={<Construction/>}/>
      <Route path='/ewaste' element={<EWaste/>}/>
      <Route path='/furnitureRemoval' element={<Furniture/>}/>

    </Routes>
    </BrowserRouter>
  )
}

export default App;
