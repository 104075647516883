import React from 'react';
import './About.css'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
import mainImage from './images/other-services-junk-removal.jpg'
import boxImage1 from './images/2149363776.jpg'
import boxImage2 from './images/16963.jpg'
import boxImage3 from './images/2151216825.jpg'
import {Helmet} from 'react-helmet'


function About() {
  return (
    <>
      <head>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
        />
        <link
          href="/Content/About.css"
          rel="stylesheet"
        />
      </head>
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y1KE9RW3J7">
</script>
<script>
{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y1KE9RW3J7');
  `}
</script>
      <title>Junk Marina-Take All My Junk</title>
      <link rel="icon" href="/favicon.ico" />
    <meta name="description" content="Contact Dubai's No 1 Free junk removal service for fast and easy removal of unwanted items. Our expert team is ready to declutter your space with hassle-free removal of furniture, appliances, and more. Get a clean, spacious home or office today!"/>
<meta name='keywords' content='junk dubai, 
take my junk dubai, 
800 junk dubai, 
remove my junk dubai, 
take my junk dubai contact number, 
800 junk dubai number, 
sell my junk dubai, 
800 got junk dubai reviews, 
remove junk dubai, 
take my junk dubai photos, 
take my junk dubai municipality, 
dubai scrap auction, 
dubai scrap price, 
junkyard dubai, 
dubai junk cars, 
junk food available in dubai, 
what not to buy in dubai, 
what is worth buying in dubai, 
where to buy cheap things in dubai, 
dubai junk yard, 
junk busters dubai, 
scrap buyers dubai, 
dubai scrap business, 
trash bin dubai, 
garbage bin dubai, 
dubai scrap ban, 
garbage bag dubai, 
buy junk dubai, 
buy my junk dubai, 
best junk food in dubai, 
junk buyers in dubai, 
dubai junkyard cars, 
junk collection dubai, 
junk collection dubai free, 
dubai salvage cars for sale, 
rubbish collection dubai, 
scrap company dubai, 
trash collection dubai, 
dubai scrap car parts, 
garbage chute dubai, 
garbage company dubai, 
collect my junk dubai, 
clean my junk dubai, 
dubai municipality junk collection, 
free junk collection dubai, 
dubai car junk yard, , 
garbage disposal dubai, 
dubai rubbish dump, 
rubbish disposal dubai, 
dubai scrap dealer in india, 
washing machine price in dubai dubizzle, 
junk dubai free, 
distributors in dubai, 
dubai scrap export ban, 
dubai scrap exporters, 
junk fitness dubai, 
junk food dubai, 
scrap from dubai, 
salvage ferrari dubai, 
cheap goods in dubai, 
free junk removal dubai, 
junk furniture removal dubai, 
where to buy cheap furniture in dubai, 
fat loss treatment in dubai, 
where to buy cheap gadgets in dubai, 
scrap gold dubai, 
unwanted gifts dubai, 
get my junk dubai, 
junk gym dubai, 
gac dealer in dubai, 
g.i. junk removal, 	
home junk removal dubai, 	
trash in dubai, 	
garbage in dubai, 	
dubai trash island, 	
garbage incinerator dubai, 	
trash in dubai today, 
junk removal in dubai, 
junk yard in dubai, 
junk cars in dubai, 
junk shop in dubai, 
junk food in dubai, 
just junk locations, 
take my junk dubai reviews, 
junk kouture dubai, 
komatsu dealer in dubai, 	
k junk, 	
dubai scrap market, 
scrap metal dubai, 	
dubai trash mountain, 
dubai rubbish mountain, 
dubai scrap market cars, 
dubai scrap market suppliers, 
dubai scrap market area, 
dubai municipality junk removal, 
my junk dubai, 
junk to go removal, 
how can i get dubai fine discount, 
junk pickup dubai'/>
    </Helmet>
      <MyNavbar/>
      <div className="blog-card">
        <h1>About US</h1>
      </div>

      {/* ============================================================================================================ */}
      <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
        <div className="junk-removal-info-section">
          <div className="free-junk-info-div">
            <h1>Why Choose <span className="number-design">Us?</span></h1>
            <p>
              Junk Marina offers a hassle-free, environmentally-conscious
              household removal service that eliminates your concerns about the
              collection and disposal of your old items. As the UAE’s trusted
              household junk removal service, Take My Junk has garnered over 15
              years of expertise in efficiently and responsibly handling household
              junk removal, all at no cost to you.
            </p>
          </div>
          <div className="junk-removal-info-img">
            <img
              src={mainImage}
                            alt="Main Junk Marina About Us "
              className="junk-removing-img"
            />
          </div>
        </div>
      </div>

      {/* ========================================================================================= */}
      <section className="call-us-section">
        <div className="container">
          <h2 className="contect-num">
            <span className="number-design">Junk Marina </span>Is The Most Trusted Junk Removal Company in UAE!.
          </h2>
        </div>
      </section>
      {/* =========================================Boxes================================================ */}

      <section className="junk-boxes">
        <div className="container">
          <div className="junk-boxes-card">
            <div className="junk-boxes-card-content">
              <h3>Furniture Removal Dubai</h3>
              <p>
                Our Furniture Removal Company offers an efficient, secure, and
                eco-conscious furniture removal service, alleviating your concerns
                regarding furniture pickup and disposal. Furthermore, we guarantee
                that your old furniture items find their way to the appropriate
                destination, whether it be a recycling facility, ensuring
                environmentally responsible disposal of your furniture.
              </p>
              <div className="btn-container"
                   style={{ textAlign: 'center', padding: '20px 0' }}>
                      <a className="number-btn" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer"><i className="ri-whatsapp-line"></i>056 5417470</a>
              </div>
              <div className="boxes-card-image-div">
                <img
                  src={boxImage1}
                  alt="img"
                  className="junk-boxes-card-content-img"
                />
              </div>
            </div>
            <div className="junk-boxes-card-content">
              <h3>
                Old Appliance<br />
                Removal
              </h3>
              <p>
                Disposing of your old appliances can be a challenging endeavor,
                involving both labor and adherence to environmentally-friendly
                processes. Mere dumping is not an option; proper handling in
                compliance with waste removal regulations and environmental
                considerations is essential. Our team is well-equipped to manage
                appliances of any size or bulk with ease.
              </p>
              <div className="btn-container"
                   style={{ textAlign: 'center', padding: '20px 0' }}>
                      <a className="number-btn" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer"><i className="ri-whatsapp-line"></i>056 5417470</a>
              </div>
              <div className="boxes-card-image-div">
                <img
                  src={boxImage2}
                  className="junk-boxes-card-content-img"
                  alt="About Junk Marina"
                />
              </div>
            </div>
            <div className="junk-boxes-card-content">
              <h3>HouseHold Junk Removal</h3>
              <p>
                We recognize the difficulties involved in disposing of furniture,
                old equipment, and other household items. That's why we've
                collaborated with service providers to bring you a household junk
                collection service in your local community. Most items can be
                collected for free, and we've implemented strict protocols to
                ensure compliance with social distancing norms and guidelines.
              </p>
              <div className="btn-container"
                   style={{ textAlign: 'center', padding: '20px 0' }}>
                      <a className="number-btn" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer"><i className="ri-whatsapp-line"></i>056 5417470</a>
              </div>
              <div className="boxes-card-image-div">
                <img
                  src={boxImage3}
                  alt="About Junk Marina removal "
                  className="junk-boxes-card-content-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="call-us-section">
        <div className="container">
          <h2 className="contect-num">
            Ready to get rid of junk? Book
            <span className="number-design">Junk Marina</span> services now!.
          </h2>
          <p className="Service-design">
            We're ready to help with Junk Removal! Call us or WhatsApp on
            <a style={{ marginLeft: '10px' }} className="number" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer">056 5417470</a>
          </p>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default About;
