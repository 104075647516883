import image1260 from './images/1260.jpg';
import image16963 from './images/16963.jpg';
import image2289 from './images/2289.jpg';
import image2151216825 from './images/2151216825.jpg';
import image1 from './images/2148610386.jpg';
import image2 from './images/2148950914.jpg';
import image3 from './images/2149311264.jpg';
import image4 from './images/2149363776.jpg';
import image5 from './images/2151216825.jpg';
import image6 from './images/2151300106.jpg';
import image7 from './images/junk-marina.jpeg';
import image8 from './images/WhatsApp-Image-at-2.26.30-AM.jpeg';
import image9 from './images/WhatsApp-Image-at-2.26.33-AM.jpeg';
import image10 from './images/WhatsApp-Image-at-2.26.34-AM-1.jpeg';
import image11 from './images/WhatsApp-Image-at-2.26.34-AM.jpeg';
import image12 from './images/WhatsApp-Image-at-2.26.35-AM.jpeg';
import image13 from './images/WhatsApp-Image-at-2.26.37-AM.jpeg';
import image14 from './images/WhatsApp-Image-at-8.03.38-PM.jpeg';
import image15 from './images/WhatsApp-Image-at-8.03.39-PM.jpeg';
import image16 from './images/WhatsApp-Image-at-8.03.41-PM.jpeg';
import image17 from './images/WhatsApp-Image-at-8.03.42-PM.jpeg';
import image18 from './images/2151300106.jpg';
import image19 from './images/2148610386.jpg';
import image20 from './images/2148950914.jpg';
import image21 from './images/2149311264.jpg';
import image22 from './images/2149363776.jpg';
import image23 from './images/2149363776.jpg';

import './Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
import Carousel from './Carousel';



function Home() {
    const [selectedAcc, setSelectedAcc] = useState('acc1');
    
    const handleAccChange = (accId) => {
        setSelectedAcc(accId);
    };
  return (
    <>
    <MyNavbar/>
    <Carousel/>
    {/* make a slider here  */}
    <section className="Contact-section-boxes">
      <div className="container">
        <div className="free-trial-card">
          <div>
            <div className="content-flex">
              <div>
                <i className="ri-time-line"></i>
              </div>
              <div>
                <h4>OPEN 24/7</h4>
                <p>24/7: Always at your service!</p>
              </div>
            </div>
          </div>
          <div className="content-flex">
            <div>
              <i className="ri-phone-line"></i>
            </div>
            <div>
              <h4>CALL US ANYTIME</h4>
              <p>056 5417470</p>
            </div>
          </div>
          <div className="content-flex">
            <div>
              <i className="ri-mail-send-line"></i>
            </div>
            <div>
              <h4>EMAIL US</h4>
              <p>Junkmarina1@gmail.com</p>
            </div>
          </div>
          <div className="btn-container">
            <a href="/ContactUs" className="free-junk-btn">Free Junk Removal</a>
          </div>
        </div>
      </div>
    </section>
    {/* ======================================================== */}
    <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
      <div className="junk-removal-info-section">
        <div className="free-junk-info-div">
          <h1>Free Junk Remover</h1>
          <p>
            Free Junk Remover provides junk removal services in all over UAE, specially in Dubai,
            Abu Dhabi and Ajman. Whether it's old furnishings, trash, apparatuses, beds,
            or outright garbage - your business has an issue that needs a quick arrangement.
            That is the place where we come in. Our group gives garbage evacuation benefits
            that are protected, quick, reasonable, or more of all...easy.
          </p>
        </div>
        <div className="junk-removal-info-img">
          <img src={image2151216825} className="junk-removing-img" alt="Junk Removal" />
        </div>
      </div>
    </div>
            {/* =================================================================== */}
            <section className="junk-boxes">
      <div className="container">
        <div className="junk-boxes-card">
          <div className="junk-boxes-card-content">
            <h3>Book Online or Give us a Call</h3>
            <p>Just pick a 2-hour arrival window. Same day appointments are often available!</p>
            <div className="btn-container" style={{ textAlign: 'center', padding: '20px 0' }}>
              <a className="number-btn" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer"><i className="ri-whatsapp-line"></i>056 5417470</a>
            </div>
            <div className="boxes-card-image-div">
              <img src={image1260} className="junk-boxes-card-content-img" alt="Book Online" />
            </div>
          </div>
          <div className="junk-boxes-card-content">
            <h3>We'll provide a FREE Estimate.</h3>
            <p>Get a written quote by displaying your junk to our helpful representative.</p>
            <div className="btn-container" style={{ textAlign: 'center', padding: '20px 0' }}>
              <a className="number-btn" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer"><i className="ri-whatsapp-line"></i>056 5417470</a>
            </div>
            <div className="boxes-card-image-div">
              <img src={image16963} className="junk-boxes-card-content-img" alt="Free Estimate Junk" />
            </div>
          </div>
          <div className="junk-boxes-card-content">
            <h3>Happy? We'll remove your junk instantly.</h3>
            <p>Stress-free removal awaits. Kick back, we've got this covered!</p>
            <div className="btn-container" style={{ textAlign: 'center', padding: '20px 0' }}>
              <a className="number-btn" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer"><i className="ri-whatsapp-line"></i>056 5417470</a>
            </div>
            <div className="boxes-card-image-div">
              <img src={image2289} className="junk-boxes-card-content-img" alt="Instant Junk Removal" />
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* =============================Gallary======================= */}
    <div className="container">
      <div className="container" style={{ textAlign: 'center', padding: '20px 0' }}>
        <h1><a href="/Gallery" className="underline" >Gallery</a></h1>
      </div>
      <div className="collection">
      <div className="item">
  <img src={image1} alt="Junk  Marina- Soda Cans" />
</div>
<div className="item">
  <img src={image2} alt="Junk  Marina- Items" />
</div>
<div className="item">
  <img src={image3} alt="Junk  Marina- Appliances" />
</div>
<div className="item">
  <img src={image4} alt="Junk  Marina- Chair" />
</div>
<div className="item">
  <img src={image5} alt="Junk  Marina- Furniture" />
</div>
<div className="item">
  <img src={image6} alt="Junk  Marina- Electronics" />
</div>
<div className="item">
  <img src={image7} alt="Junk  Marina- Mattress" />
</div>
<div className="item">
  <img src={image8} alt="Junk  Marina- Yard Waste" />
</div>
<div className="item">
  <img src={image9} alt="Junk  Marina- Renovation Debris" />
</div>
<div className="item">
  <img src={image10} alt="Junk Marina - Construction Materials" />
</div>
<div className="item">
  <img src={image11} alt="Junk Marina - Scrap Metal" />
</div>
<div className="item">
  <img src={image12} alt="Junk Marina - Tires" />
</div>
<div className="item">
  <img src={image13} alt="Junk Marina - E-Waste" />
</div>
<div className="item">
  <img src={image14} alt="Junk Marina - Hazardous Waste" />
</div>
<div className="item">
  <img src={image15} alt="Junk Marina - Old TV" />
</div>
<div className="item">
  <img src={image16} alt="Junk Marina - Refrigerator" />
</div>
<div className="item">
  <img src={image17} alt="Junk Marina - Couch" />
</div>
<div className="item">
  <img src={image18} alt="Junk Marina - Carpet" />
</div>
<div className="item">
  <img src={image19} alt="Junk Marina - Hot Tub" />
</div>
<div className="item">
  <img src={image20} alt="Junk Marina - Old Furniture" />
</div>
<div className="item">
  <img src={image21} alt="Junk Marina - Clutter" />
</div>
<div className="item">
  <img src={image22} alt="Junk Marina - Junk Pile" />
</div>
<div className="item">
  <img src={image23} alt="Junk Marina - Other Items" />
</div>

      </div>
      <div className="modal" id="lightbox">
        <div className="modal-content">
          {/* <button className="modal-close" onClick={closeModal}>&times;</button> */}
          <img id="lightbox_img" alt="Junk Marina Contact Us" />
        </div>
      </div>
    </div>
    {/* ===================================Accordians=================================== */}
    <section className="Accordian-section">
            <div className="container">
                <h1>Frequently asked questions</h1>
                <div className="acc-kontainer">
                    <div>
                        <input
                            type="radio"
                            name="acc"
                            id="acc1"
                            checked={selectedAcc === 'acc1'}
                            onChange={() => handleAccChange('acc1')}
                        />
                        <label htmlFor="acc1">
                            <i className="ri-question-answer-fill"></i> What services does Junk Marina provide for residential junk removal in Dubai?
                        </label>
                        <div className="acc-body">
                            Junk Marina offers efficient and reliable Residential Junk Removal Services in Dubai, providing homeowners with a hassle-free solution to clear out unwanted items and clutter from their homes, including old furniture, appliances, electronics, and general debris.
                        </div>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="acc"
                            id="acc2"
                            checked={selectedAcc === 'acc2'}
                            onChange={() => handleAccChange('acc2')}
                        />
                        <label htmlFor="acc2">
                            <i className="ri-question-answer-fill"></i> What services does Junk Marina provide for commercial junk removal in Dubai?
                        </label>
                        <div className="acc-body">
                            Junk Marina offers exceptional Commercial Junk Removal Services in Dubai, serving businesses of all sizes. Our skilled Dubai junk removal team efficiently handles office furniture, electronic waste, paper clutter, and other commercial debris. Reach out to our Junk Cleaning Dubai team today for peace of mind!
                        </div>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="acc"
                            id="acc3"
                            checked={selectedAcc === 'acc3'}
                            onChange={() => handleAccChange('acc3')}
                        />
                        <label htmlFor="acc3">
                            <i className="ri-question-answer-fill"></i> How does Junk Marina handle electronic waste disposal in Dubai?
                        </label>
                        <div className="acc-body">
                            Junk Marina provides professional E-Waste Removal Services in Dubai, offering a responsible solution through Take My Junk for electronic waste disposal. We are dedicated to environmental protection, ensuring the safe disposal of old computers, laptops, printers, phones, and other electronic items. Choose our Junk Collection Dubai Services today for a clutter-free life!
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </>
  );
}

export default Home;
