import React from 'react';
import './Scrap.css'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';


const Sacrap = () => {
    return (
        <>
            <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <link href="/Content/scrap.css" rel="stylesheet" />
            </head>
            <MyNavbar/>
            <div className="blog-card">
                <h1>Scrap Metal Removal</h1>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        <span className="number-design">Junk Marina</span> – Your Premier Scrap
                        Metal Removal Service in the UAE
                    </h2>
                    <p>
                        In a rapidly evolving world, where industries thrive and technological
                        advancements continue to reshape our lives, there is an ever-growing
                        demand for the responsible management of scrap metal. This is where
                        “Junk Marina” steps in as a beacon of sustainability and excellence in
                        the United Arab Emirates (UAE). “Junk Marina” is not just a scrap
                        metal removal service; it is a commitment to environmental
                        responsibility, efficiency, and customer satisfaction.
                    </p>
                </div>
            </section>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="free-junk-info-div">
                    <h1>01.<span className="number-design">Environmentally</span> Responsible</h1>
                    <p>
                        At the heart of “Junk Marina” lies an unwavering commitment to
                        environmental responsibility. Scrap metal, if not handled correctly,
                        can be a source of pollution and environmental degradation. “Junk
                        Marina” takes this challenge head-on by employing eco-friendly
                        practices throughout its operations. By recycling and reusing scrap
                        metal, the service contributes to the reduction of carbon emissions
                        and the preservation of precious natural resources. In a region where
                        sustainability is a growing concern, “Junk Marina” stands as a pioneer
                        in sustainable scrap metal management.
                    </p>
                </div>
            </div>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="free-junk-info-div">
                    <h1>02.<span className="number-design">Comprehensive</span> Services</h1>
                    <p>
                        “Junk Marina” offers a wide range of scrap metal removal services
                        tailored to meet the unique needs of businesses and individuals across
                        the UAE. Whether you have a small collection of metal items cluttering
                        your backyard or need an entire industrial site cleared of scrap,
                        “Junk Marina” has the expertise and equipment to handle it all. From
                        dismantling and transportation to recycling and disposal, they provide
                        a turnkey solution for your scrap metal needs.
                    </p>
                </div>
            </div>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="free-junk-info-div">
                    <h1>03.<span className="number-design">Cutting-Edge</span> Technology</h1>
                    <p>
                        To ensure the utmost efficiency and safety in their operations, “Junk
                        Marina” employs cutting-edge technology. Their state-of-the-art
                        equipment and machinery make scrap metal removal a seamless process.
                        This not only reduces the time it takes to complete a project but also
                        minimizes potential risks associated with manual handling.
                    </p>
                </div>
            </div>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="free-junk-info-div">
                    <h1>04.<span className="number-design">Professional</span> Team</h1>
                    <p>
                        Behind the success of “Junk Marina” is a team of dedicated
                        professionals who are experts in scrap metal removal. They are
                        well-trained in safety protocols, environmentally responsible
                        practices, and customer service. “Junk Marina” takes pride in its
                        team’s ability to efficiently manage scrap metal projects of all sizes
                        and complexities while maintaining the highest level of
                        professionalism.
                    </p>
                </div>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Contact Junk Marina <span className="number-design">Junk Marina</span> for
                        top-notch cleanup and compliance services!.
                    </h2>
                    <p className="Service-design">
                        We're ready to help with Junk Removal! Call us or WhatsApp on
                        <a style={{ marginLeft: '10px'}} className="number"
                           href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested"
                           target="_blank" rel="noreferrer">056 5417470</a>
                    </p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default Sacrap;
