import image1 from './images/2148610386.jpg';
import image2 from './images/2148950914.jpg';
import image3 from './images/2149311264.jpg';
import image4 from './images/2149363776.jpg';
import image5 from './images/2151216825.jpg';
import image6 from './images/2151300106.jpg';
import image7 from './images/junk-marina.jpeg';
import image8 from './images/WhatsApp-Image-at-2.26.30-AM.jpeg';
import image9 from './images/WhatsApp-Image-at-2.26.33-AM.jpeg';
import image10 from './images/WhatsApp-Image-at-2.26.34-AM-1.jpeg';
import image11 from './images/WhatsApp-Image-at-2.26.34-AM.jpeg';
import image12 from './images/WhatsApp-Image-at-2.26.35-AM.jpeg';
import image13 from './images/WhatsApp-Image-at-2.26.37-AM.jpeg';
import image14 from './images/WhatsApp-Image-at-8.03.38-PM.jpeg';
import image15 from './images/WhatsApp-Image-at-8.03.39-PM.jpeg';
import image16 from './images/WhatsApp-Image-at-8.03.41-PM.jpeg';
import image17 from './images/WhatsApp-Image-at-8.03.42-PM.jpeg';
import image18 from './images/2151300106.jpg';
import image19 from './images/2148610386.jpg';
import image20 from './images/2148950914.jpg';
import image21 from './images/2149311264.jpg';
import image22 from './images/2149363776.jpg';
import image23 from './images/2149363776.jpg';
// import Footer from './Footer.js';
import './Gallery.css'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';







function Gallery() {
   
    return (
      <>
      <MyNavbar/>
<div class="contact-us-card">
    <h1>Gallery</h1>
</div>
{/* <!-- =============================================Gallary=================================================== --> */}
<div>
    <h2 class="contect-num">
        Journey through our eco-chic <span class="number-design">gallery</span> and witness clutter's downfall!.
    </h2>
</div>
{/* =============================Gallary======================= */}
<div className="container">
      <div className="container" style={{ textAlign: 'center', padding: '20px 0' }}>
        {/* <h1><a href="/Gallery" className="underline">Gallery</a></h1> */}
      </div>
      <div className="collection">
      <div className="item">
  <img src={image1} alt="Junk  Marina- Soda Cans" />
</div>
<div className="item">
  <img src={image2} alt="Junk  Marina- Items" />
</div>
<div className="item">
  <img src={image3} alt="Junk  Marina- Appliances" />
</div>
<div className="item">
  <img src={image4} alt="Junk  Marina- Chair" />
</div>
<div className="item">
  <img src={image5} alt="Junk  Marina- Furniture" />
</div>
<div className="item">
  <img src={image6} alt="Junk  Marina- Electronics" />
</div>
<div className="item">
  <img src={image7} alt="Junk  Marina- Mattress" />
</div>
<div className="item">
  <img src={image8} alt="Junk  Marina- Yard Waste" />
</div>
<div className="item">
  <img src={image9} alt="Junk  Marina- Renovation Debris" />
</div>
<div className="item">
  <img src={image10} alt="Junk Marina - Construction Materials" />
</div>
<div className="item">
  <img src={image11} alt="Junk Marina - Scrap Metal" />
</div>
<div className="item">
  <img src={image12} alt="Junk Marina - Tires" />
</div>
<div className="item">
  <img src={image13} alt="Junk Marina - E-Waste" />
</div>
<div className="item">
  <img src={image14} alt="Junk Marina - Hazardous Waste" />
</div>
<div className="item">
  <img src={image15} alt="Junk Marina - Old TV" />
</div>
<div className="item">
  <img src={image16} alt="Junk Marina - Refrigerator" />
</div>
<div className="item">
  <img src={image17} alt="Junk Marina - Couch" />
</div>
<div className="item">
  <img src={image18} alt="Junk Marina - Carpet" />
</div>
<div className="item">
  <img src={image19} alt="Junk Marina - Hot Tub" />
</div>
<div className="item">
  <img src={image20} alt="Junk Marina - Old Furniture" />
</div>
<div className="item">
  <img src={image21} alt="Junk Marina - Clutter" />
</div>
<div className="item">
  <img src={image22} alt="Junk Marina - Junk Pile" />
</div>
<div className="item">
  <img src={image23} alt="Junk Marina - Other Items" />
</div>

      </div>
      <div className="modal" id="lightbox">
        <div className="modal-content">
          {/* <button className="modal-close" onClick={closeModal}>&times;</button> */}
          <img id="lightbox_img" alt="junk marina box junk" />
        </div>
      </div>
    </div>
    <Footer/>
      </>
    );
  }

export default Gallery;
