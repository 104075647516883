import React from 'react';
import './Locations.css'
import img from './images/1260.jpg'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
function Locations() {
  return (
    <>
      <head>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
        />
        <link
          href="/Content/Location.css"
          rel="stylesheet"
        />
      </head>
      <MyNavbar/>
      <div className="blog-card">
        <h1>Locations</h1>
      </div>

      {/* ============================================================================================================ */}
      <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
        <div className="junk-removal-info-section">
          <div className="free-junk-info-div">
            <h1>
              Explore Junk Drop-Off Points in <span className="number-design">Dubai</span>
            </h1>
            <p>
              Discover effortless junk removal services tailored for Dubai residents. With our expert team, say goodbye to clutter and hello to a pristine space. Let us handle the cleanup while you enjoy the Dubai lifestyle hassle-free.
            </p>
          </div>
          <div className="junk-removal-info-img">
            <img
              src={img}
              alt="junk removal"
              className="junk-removing-img"
            />
          </div>
        </div>
      </div>

      {/* ========================================================================================= */}
      {/* ========================================================================================= */}
      <div className="container">
        <h2 className="contect-num">
          Looking for the Best <span className="number-design">Junk Collection</span> Near Me in Dubai?
        </h2>
        <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 400 }}>No matter what type of junk, waste, or rubbish you need to be collected, Get Junk Marina Collection Dubai can get the job done! Our friendly team can load your rubbish into our trucks quickly and without hassle. Our Dubai Junk Collection team offers services all across Dubai. Just call us on 05557676 and get the job done!</p>
        <div className="list-container">
          <div className="list-column">
            <ul>
              <li><span className="dot"></span>Dubai Marina</li>
              <li><span className="dot"></span>Palm Jumeirah</li>
              <li><span className="dot"></span>Jumeirah 1 or 2</li>
              <li><span className="dot"></span>Al Barsha 1</li>
              <li><span className="dot"></span>Barsha South</li>
              <li><span className="dot"></span>Dawonton</li>
              <li><span className="dot"></span>DIFC</li>
              <li><span className="dot"></span>Business Bay</li>
              <li><span className="dot"></span>Creek Dubai</li>
              <li><span className="dot"></span>Sport City</li>
            </ul>
          </div>
          <div className="list-column">
            <ul>
              <li><span className="dot"></span>Media City</li>
              <li><span className="dot"></span>Motor City</li>
              <li><span className="dot"></span>Arabian Ranches</li>
              <li><span className="dot"></span>Marina Dubai</li>
              <li><span className="dot"></span>Jumeirah Lakes Towers (JLT)</li>
              <li><span className="dot"></span>Arjan</li>
              <li><span className="dot"></span>Jumeirah Village Circle (JVC)</li>
              <li><span className="dot"></span>Mirdif</li>
              <li><span className="dot"></span>Sheikh Zaid Road</li>
              <li><span className="dot"></span>Jumeirah Beach Residence (JBR)</li>
            </ul>
          </div>
        </div>
      </div>
      {/* ========================================================================================= */}
      <section className="call-us-section">
        <div className="container">
          <h2 className="contect-num">
            Transform your space with ease! Trust Junk Marina
            <span className="number-design">Junk Marina</span> to make clutter disappear!.
          </h2>
          <p className="Service-design">
            We're ready to help with Junk Removal! Call us or WhatsApp on 
            <a className="number" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer">056 5417470</a>
          </p>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Locations;
