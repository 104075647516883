import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'; // Use NavLink for active link styling
import './MyNavbar.css';
import logo from './images/logo.png';

function MyNavbar() {
  const [activeLink, setActiveLink] = useState('/');

  return (
    <div className="navigation-wrap start-header start-style">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  height="30"
                  className="d-inline-block align-top"
                  alt="Logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="navbar-design">
                  <NavLink exact to="/" className="nav-link" activeClassName="active" onClick={() => setActiveLink('/')}>Home</NavLink>
                  <NavDropdown title="Services" id="collasible-nav-dropdown">
                    <NavDropdown.Item as={NavLink} to="/ConstructionRemoval">Construction Debris Removal</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/ewaste">E-Waste Removal</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/furnitureRemoval">Furniture Removal</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/officeRemoval">Office Junk Removal</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/ResidentialRemoval">Residential & Home Junk Removal</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/Scrap">Scrap Metal Removal</NavDropdown.Item>
                  </NavDropdown>
                  <NavLink to="/Gallery" className="nav-link" activeClassName="active" onClick={() => setActiveLink('/Gallery')}>Gallery</NavLink>
                  <NavLink to="/locations" className="nav-link" activeClassName="active" onClick={() => setActiveLink('/locations')}>Locations</NavLink>
                  <NavLink to="/about" className="nav-link" activeClassName="active" onClick={() => setActiveLink('/about')}>About Us</NavLink>
                  <NavLink to="/blog" className="nav-link" activeClassName="active" onClick={() => setActiveLink('/blog')}>Blog</NavLink>
                  <NavLink to="/ContactUs" className="nav-link" activeClassName="active" onClick={() => setActiveLink('/ContactUs')}>Contact Us</NavLink>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyNavbar;
