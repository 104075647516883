import React from 'react';
import './JunkRemovalAll.css'
import img from './images/2149363776.jpg'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';

const ResidentalRemoval = () => {
    return (
        <>
            {/* <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <link href="/Content/JunkRemovalAll.css" rel="stylesheet" />
            </head> */}
            <MyNavbar/>
            <div className="blog-card">
                <h1>Residential & Home Junk Removal</h1>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        For residential and home junk removal in the UAE 
                        <span className="number-design">Junk Marina</span> keeping spaces clean
                        and clear with excellence.
                    </h2>
                    <p>
                        In the busy cities of the UAE, keeping homes junk-free is crucial.
                        That's where "Junk Marina" comes in. We specialize in removing
                        unwanted items from homes across the UAE, ensuring cleanliness and
                        order. Our team is dedicated to excellence, swiftly clearing out
                        clutter to create a harmonious living environment for you.
                    </p>
                </div>
            </section>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="junk-removal-info-section">
                    <div className="free-junk-info-div">
                        <h1>
                            Why Choose <span className="number-design">Junk Marina</span> for
                            Residential & Home Junk Removal?
                        </h1>
                    </div>
                    <div className="junk-removal-info-img">
                        <img src={img} className="junk-removing-img" alt="Junk Removal Resident" />
                    </div>
                </div>
            </div>
            <section className="dl-blurbs">
                <dl>
                    <dt>Household</dt>
                    <dd>
                        From old furniture and appliances to garden debris and construction
                        waste, Junk Marina takes care of all your household junk removal
                        needs. Our team ensures safe and eco-friendly disposal methods.
                    </dd>
                    <dt>Cleanouts</dt>
                    <dd>
                        Dealing with the estate of a loved one can be emotionally challenging.
                        Let Junk Marina handle the burden of clearing out the space, allowing
                        you to focus on what truly matters.
                    </dd>
                    <dt>Appliance</dt>
                    <dd>
                        When it’s time to upgrade your appliances, Junk Marina can remove and
                        dispose of the old ones safely, saving you time and effort.
                    </dd>
                </dl>
                <dl>
                    <dt>Electronics</dt>
                    <dd>
                        Electronic waste poses environmental hazards. We offer responsible
                        electronics recycling services to ensure these items are disposed of
                        correctly.
                    </dd>
                    <dt>Yard-Debris</dt>
                    <dd>
                        Keep your outdoor space pristine with our yard debris removal
                        services. Whether it’s tree branches, grass clippings, or garden
                        waste, we’ll take care of it.
                    </dd>

                    <dt>Hoarding</dt>
                    <dd>
                        Hoarding situations require sensitivity and expertise. Our experienced
                        team can help with hoarding cleanup, restoring homes to safe and
                        habitable conditions.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        What makes <span className="number-design">Junk Marina's</span> disposal methods environmentally friendly?
                    </h2>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Service</dt>
                    <dd>
                        We understand that junk removal needs can be urgent. Junk Marina offers prompt and reliable services to cater to your immediate needs.
                    </dd>
                    <dt>Eco-Friendly</dt>
                    <dd>
                        We are committed to protecting the environment. Our disposal methods prioritize recycling and responsible waste management.
                    </dd>
                    <dt>Pricing</dt>
                    <dd>
                        With Junk Marina, you’ll never face hidden fees or surprises. We provide upfront, transparent pricing for our services.
                    </dd>
                </dl>
                <dl>
                    <dt>Experienced</dt>
                    <dd>
                        Our team is highly trained and experienced, ensuring the safe and efficient removal of all types of junk.
                    </dd>
                    <dt>Satisfaction</dt>
                    <dd>
                        Your satisfaction is our ultimate goal. We go above and beyond to ensure you are delighted with our services.
                    </dd>
                    <dt>Clean-Up</dt>
                    <dd>
                        After debris removal, we conduct a thorough clean-up to ensure your site is left spotless and safe for the next phase of your project.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Get in touch with <span className="number-design">Junk Marina</span> for premium cleanup and adherence services!.
                    </h2>
                    <p className="Service-design">
                        We're ready to help with Junk Removal! Call us or WhatsApp on
                        <a style={{ marginLeft: '10px' }} className="number" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank" rel="noreferrer">056 5417470</a>
                    </p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default ResidentalRemoval;
