import React from 'react';
import img from './images/2151216825.jpg'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
import './Construction.css'
const ConstructionRemoval = () => {
    return (
        <>
            {/* <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <link href="/Content/ConstructionJunkRemoval.css" rel="stylesheet" />
            </head> */}
            <MyNavbar/>
            <div className="blog-card">
                <h1>Construction Debris Removal</h1>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Junk Marina: Your
                        <span className="number-design">Premier Partner</span> in Construction
                        Debris Removal
                    </h2>
                    <p>
                        Construction projects can be messy affairs, and when the dust settles,
                        there’s often a substantial amount of debris left behind. Handling
                        construction debris can be a daunting task, but fear not! Junk Marina
                        is here to provide you with top-notch construction debris removal
                        services that will leave your site clean, safe, and ready for its next
                        phase.
                    </p>
                </div>
            </section>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="junk-removal-info-section">
                    <div className="free-junk-info-div">
                        <h1>
                            Why Choose <span className="number-design">Junk Marina</span> for
                            Construction Debris Removal?
                        </h1>
                    </div>
                    <div className="junk-removal-info-img">
                        <img src={img} className="junk-removing-img" alt="construction debris removal" />
                    </div>
                </div>
            </div>

            <section className="dl-blurbs">
                <dl>
                    <dt>Expertise</dt>
                    <dd>
                        At Junk Marina, we specialize in handling all kinds of construction
                        debris efficiently and in compliance with local regulations.
                    </dd>
                    <dt>Safety First</dt>
                    <dd>
                        Safety comes first with construction debris. We take every step to
                        keep our team, your property, and the environment safe. We follow
                        strict safety rules, use the right gear, and handle debris
                        responsibly.
                    </dd>
                    <dt>Efficiency</dt>
                    <dd>
                        We work fast and save you money. Our skilled team and good equipment
                        quickly clear debris, keeping your project moving. Whether it's a
                        small job or a big one, we've got it covered.
                    </dd>
                </dl>
                <dl>
                    <dt>Eco-Friendly</dt>
                    <dd>
                        We're eco-friendly. Junk Marina recycles and responsibly disposes of
                        construction debris to minimize environmental impact.
                    </dd>
                    <dt>Custom Fit</dt>
                    <dd>
                        Tailored solutions for every site. Junk Marina customizes debris
                        removal to fit your project perfectly, whether it's a one-time cleanup
                        or ongoing service.
                    </dd>

                    <dt>Pricing</dt>
                    <dd>
                        We believe in fair and transparent pricing. Our pricing is
                        competitive, and there are no hidden fees. You’ll know exactly what to
                        expect, allowing you to budget with confidence.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        We handle everything from
                        <span className="number-design">assessment to cleanup</span>, ensuring
                        compliance and spotless results!.
                    </h2>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Assessment</dt>
                    <dd>
                        We start by assessing your construction site and debris removal needs. This allows us to develop a customized plan that ensures efficient and thorough cleanup.
                    </dd>
                    <dt>Removal</dt>
                    <dd>
                        Our skilled team arrives on-site with the necessary equipment and begins the removal process. We handle everything from the loading to the transportation of debris.
                    </dd>
                    <dt>Recycling</dt>
                    <dd>
                        We take the debris to appropriate recycling centers or disposal facilities, ensuring that materials are processed responsibly and in compliance with local regulations.
                    </dd>
                </dl>
                <dl>
                    <dt>Clean-Up</dt>
                    <dd>
                        After debris removal, we conduct a thorough clean-up to ensure your site is left spotless and safe for the next phase of your project.
                    </dd>
                    <dt>Documentation</dt>
                    <dd>
                        We provide you with all the necessary documentation to prove responsible disposal, helping you meet any regulatory requirements.
                    </dd>
                    <dt>Site Inspection</dt>
                    <dd>
                        Before finalizing the project, we conduct a comprehensive site inspection to guarantee all debris has been effectively removed and the area is ready for the next stage of development.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Connect with <span className="number-design">Junk Marina</span> for top-tier cleanup and compliance services!.
                    </h2>
                    <p className="Service-design">
                        We're ready to help with Junk Removal! Call us or WhatsApp on
                        <a style={{ marginLeft: '10px' }} className="number" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank">056 5417470</a>
                    </p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default ConstructionRemoval;
