import React from 'react';
import img from './images/541.jpg'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
import './JunkRemovalAll.css'

const FurnitureRemoval = () => {
    return (
        <>
            {/* <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <link href="/Content/JunkRemovalAll.css" rel="stylesheet" />
            </head> */}
            <MyNavbar/>
            <div className="blog-card">
                <h1>Furniture Removal Service</h1>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        <span className="number-design">Junk Marina</span> – Your Premier
                        Furniture Removal Service in the UAE
                    </h2>
                    <p>
                        Are you tired of old, unwanted furniture cluttering up your space? Do
                        you need a reliable and efficient solution for furniture removal in
                        the United Arab Emirates (UAE)? Look no further than “Junk Marina,”
                        your premier furniture removal service. Junk Marina is your go-to
                        partner for hassle-free, eco-friendly, and professional furniture
                        removal services across the UAE.
                    </p>
                </div>
            </section>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="junk-removal-info-section">
                    <div className="free-junk-info-div">
                        <h1>
                            Why Choose <span className="number-design">Junk Marina</span> for
                            Furniture Removal?
                        </h1>
                    </div>
                    <div className="junk-removal-info-img">
                        <img src={img} className="junk-removing-img" alt="furniture removal" />
                    </div>
                </div>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Choose <span className="number-design">Junk Marina</span> for Stress-Free
                        Furniture Removal
                    </h2>
                    <p>
                        Junk Marina is your trusted partner for furniture removal in the UAE.
                        We combine professionalism, eco-friendliness, and convenience to
                        provide a service that exceeds your expectations. Say goodbye to
                        unwanted furniture cluttering your space and hello to a cleaner, more
                        organized environment. Contact Junk Marina today and experience the
                        difference in furniture removal services in the UAE. We’re here to
                        make your life easier and your space clutter-free.This is why you
                        should Choose Junk Marina:
                    </p>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Professional</dt>
                    <dd>
                        At Junk Marina, we understand the importance of professionalism. Our
                        highly trained and skilled team of removal experts ensures that your
                        furniture is handled with the utmost care, from the moment we step
                        into your home or office until the last piece is safely removed.
                    </dd>
                    <dt>Eco-Friendly</dt>
                    <dd>
                        We believe in responsible disposal practices. Junk Marina is committed
                        to environmental sustainability. We sort, recycle, and donate whenever
                        possible to minimize waste and reduce our carbon footprint. By
                        choosing us, you’re also choosing a greener future.
                    </dd>
                    <dt>Comprehensive</dt>
                    <dd>
                        Whether you have a single piece of furniture or an entire household’s
                        worth, Junk Marina is equipped to handle any job. Our services
                        encompass everything from sofas and chairs to tables, cabinets, and
                        more. We’re your one-stop solution for furniture removal.
                    </dd>
                </dl>
                <dl>
                    <dt>Convenience</dt>
                    <dd>
                        We understand that furniture removal can be a time-consuming and
                        labor-intensive task. Let us take the burden off your shoulders. Our
                        team will handle all the heavy lifting, disassembly if necessary, and
                        transportation, making the process as convenient as possible for you.
                    </dd>
                    <dt>Safety First</dt>
                    <dd>
                        Your safety and the safety of our team members are paramount. Junk
                        Marina adheres to strict safety protocols, ensuring that all removals
                        are conducted in a secure and controlled manner. Rest easy knowing
                        your property is in good hands.
                    </dd>

                    <dt>Pricing</dt>
                    <dd>
                        We believe in fair and transparent pricing. When you request a quote
                        from Junk Marina, you can trust that there won’t be any hidden fees or
                        surprises. We provide upfront pricing so you know exactly what to
                        expect.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        How Does <span className="number-design">Junk Marina</span> Work?
                    </h2>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Contact Us</dt>
                    <dd>
                        Get in touch with Junk Marina via phone or our website to request a
                        free quote. Provide us with details about the furniture you need to be
                        removed, your location, and any specific requirements you may have.
                    </dd>
                    <dt>Schedule</dt>
                    <dd>
                        Once you accept our quote, we’ll work with you to schedule a pickup
                        time that suits your convenience. We’re flexible and can accommodate
                        your timeline.
                    </dd>
                    <dt>Removal</dt>
                    <dd>
                        On the scheduled day, our expert team will arrive at your location,
                        equipped with the necessary tools and equipment. We’ll carefully
                        remove the furniture, ensuring it doesn’t cause any damage to your
                        property.
                    </dd>
                </dl>
                <dl>
                    <dt>Eco-Friendly</dt>
                    <dd>
                        After removal, we’ll sort and dispose of the furniture responsibly,
                        following environmentally friendly practices. Any items that can be
                        donated or recycled will be, reducing waste and benefiting the
                        community.
                    </dd>
                    <dt>Payment</dt>
                    <dd>
                        Once the job is complete, you’ll receive a detailed invoice with the
                        agreed-upon pricing. We offer multiple payment options for your
                        convenience.
                    </dd>
                    <dt>Clean-Up</dt>
                    <dd>
                        After debris removal, we conduct a thorough clean-up to ensure your
                        site is left spotless and safe for the next phase of your project.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Contact Junk Marina <span className="number-design">Junk Marina</span> for top-notch cleanup and compliance services!.
                    </h2>
                    <p className="Service-design">
                        We're ready to help with Junk Removal! Call us or WhatsApp on
                        <a style={{ marginLeft: '10px' }} className="number"
                           href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested"
                           target="_blank">056 5417470</a>
                    </p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default FurnitureRemoval;
