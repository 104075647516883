// CustomCarousel component
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import boxImage1 from './images/1258.jpg';
import boxImage2 from './images/2289.jpg';
import boxImage3 from './images/2293.jpg';
import './Carousel.css'

const slides = [
    {
        image: boxImage1,
        title: 'Welcome to Junk Marina',
        text: 'Extra inventory? We\'ll make it disappear like magic!',
        buttonUrl: '/ContactUs',
        buttonText: 'Contact Us'
    },
    {
        image: boxImage2,
        title: 'مرحبا بكم في جانك مارينا',
        text: 'مخزون إضافي؟ سنجعلها تختفي مثل السحر!',
        buttonUrl: '/ContactUs',
        buttonText: 'Contact Us'
    },
    {
        image: boxImage3,
        title: 'Need Professionals for Junk Clearance?',
        text: 'Serving Dubai\'s bustling urban landscape, we specialize in clearing spaces of any size, from skyscraper to villa, leaving no junk behind.',
        buttonUrl: '/ContactUs',
        buttonText: 'Contact Us'
    }
];

const CustomCarousel = () => {
    return (
        <Carousel autoPlay>
            {slides.map((slide, index) => (
                <div key={index}>
                    <img src={slide.image} alt={`Slide ${index + 1}`} className="carousel-image" />
                    <div className="legend">
                        <h2>{slide.title}</h2>
                        <p>{slide.text}</p>
                        <a href={slide.buttonUrl}>{slide.buttonText}</a>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default CustomCarousel;
