import React from 'react';
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
import './Contact.css';
import {Helmet} from 'react-helmet'

function ContactUs() {
  return (
    <>
      <head>
        <link href="/Content/contactUs.css" rel="stylesheet" />
        <link href="/Content/styleindex.css" rel="stylesheet" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
          rel="stylesheet"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        ></script>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/remixicon@2.2.0/fonts/remixicon.css"
          rel="stylesheet"
        />
      </head>
      <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y1KE9RW3J7">
</script>
<script>
{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-Y1KE9RW3J7');
  `}
</script>
      <title>Junk Marina-Take All My Junk</title>
      <link rel="icon" href="/favicon.ico" />
    <meta name="description" content="Contact Dubai's No 1 Free junk removal service for fast and easy removal of unwanted items. Our expert team is ready to declutter your space with hassle-free removal of furniture, appliances, and more. Get a clean, spacious home or office today!"/>
<meta name='keywords' content='junk dubai, 
take my junk dubai, 
800 junk dubai, 
remove my junk dubai, 
take my junk dubai contact number, 
800 junk dubai number, 
sell my junk dubai, 
800 got junk dubai reviews, 
remove junk dubai, 
take my junk dubai photos, 
take my junk dubai municipality, 
dubai scrap auction, 
dubai scrap price, 
junkyard dubai, 
dubai junk cars, 
junk food available in dubai, 
what not to buy in dubai, 
what is worth buying in dubai, 
where to buy cheap things in dubai, 
dubai junk yard, 
junk busters dubai, 
scrap buyers dubai, 
dubai scrap business, 
trash bin dubai, 
garbage bin dubai, 
dubai scrap ban, 
garbage bag dubai, 
buy junk dubai, 
buy my junk dubai, 
best junk food in dubai, 
junk buyers in dubai, 
dubai junkyard cars, 
junk collection dubai, 
junk collection dubai free, 
dubai salvage cars for sale, 
rubbish collection dubai, 
scrap company dubai, 
trash collection dubai, 
dubai scrap car parts, 
garbage chute dubai, 
garbage company dubai, 
collect my junk dubai, 
clean my junk dubai, 
dubai municipality junk collection, 
free junk collection dubai, 
dubai car junk yard, , 
garbage disposal dubai, 
dubai rubbish dump, 
rubbish disposal dubai, 
dubai scrap dealer in india, 
washing machine price in dubai dubizzle, 
junk dubai free, 
distributors in dubai, 
dubai scrap export ban, 
dubai scrap exporters, 
junk fitness dubai, 
junk food dubai, 
scrap from dubai, 
salvage ferrari dubai, 
cheap goods in dubai, 
free junk removal dubai, 
junk furniture removal dubai, 
where to buy cheap furniture in dubai, 
fat loss treatment in dubai, 
where to buy cheap gadgets in dubai, 
scrap gold dubai, 
unwanted gifts dubai, 
get my junk dubai, 
junk gym dubai, 
gac dealer in dubai, 
g.i. junk removal, 	
home junk removal dubai, 	
trash in dubai, 	
garbage in dubai, 	
dubai trash island, 	
garbage incinerator dubai, 	
trash in dubai today, 
junk removal in dubai, 
junk yard in dubai, 
junk cars in dubai, 
junk shop in dubai, 
junk food in dubai, 
just junk locations, 
take my junk dubai reviews, 
junk kouture dubai, 
komatsu dealer in dubai, 	
k junk, 	
dubai scrap market, 
scrap metal dubai, 	
dubai trash mountain, 
dubai rubbish mountain, 
dubai scrap market cars, 
dubai scrap market suppliers, 
dubai scrap market area, 
dubai municipality junk removal, 
my junk dubai, 
junk to go removal, 
how can i get dubai fine discount, 
junk pickup dubai'/>
    </Helmet>
      <MyNavbar/>
      <div className="contact-us-card">
        <h1>Contact Us</h1>
      </div>
      <div className="container">
        <h2 className="quick-msg-design-h2">
          Leave a quick message and we will get back to you shortly.
        </h2>
        <p className="quick-msg-design-p">You can also call or email us directly</p>
      </div>
      <section>
        <div className="container">
          <div className="contact-us-modal">
            <div className="first-card">
              <div>
                <h4 className="text-design">
                  <i className="ri-phone-line icon-ds"></i>Phone No:
                </h4>
                <a className="number-ds" href="tel:056 5417470" target="_self">
                056 5417470
                </a>
              </div>
              <div>
                <h4 className="text-design">
                  <i className="ri-mail-send-line icon-ds"></i>Email Us:
                </h4>
                <p className="email-design">JunkMarina1@gmail.com</p>
              </div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1713798110217!5m2!1sen!2s"
                  width="100"
                  height="350"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="form-card">
              <form
                action="https://formspree.io/f/moqgrngz"
                method="POST"
              >
                <div>
                  <div>
                    <label htmlFor="name">Name:</label>
                  </div>
                  <div>
                    <input type="text" name="name" required />
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="email">Email:</label>
                  </div>
                  <div>
                    <input type="email" name="email" required />
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="phone">Phone No:</label>
                  </div>
                  <div>
                    <input type="number" name="phone" required />
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="message">Message:</label>
                  </div>
                  <div>
                    <textarea name="message" required></textarea>
                  </div>
                  <div className="form-btn-container">
                    <button type="submit" className="submit-btn">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="call-us-section">
        <div className="container">
          <h2 className="contect-num">
            Call Junk Marina Today{" "}
            <a
              style={{ textDecoration: "none" }}
              href="tel:056 5417470"
              target="_self"
            >
              <span className="number-design">056 5417470</span>
            </a>
          </h2>
          <p className="Service-design">Customer Service hours are 24/7</p>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default ContactUs;
