import React from 'react';
import './JunkRemovalAll.css'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';
import img from './images/16963.jpg'
const EWaste = () => {
    return (
        <>
            {/* <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
                <link href="/Content/JunkRemovalAll.css" rel="stylesheet" />
            </head> */}
            <MyNavbar/>
            <div className="blog-card">
                <h1>E-Waste Removal</h1>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Revolutionizing Waste Management: <span className="number-design">Junk Marina’s</span> E-Waste Removal Service
                    </h2>
                    <p>
                        In our modern digital age, electronic devices have become an integral part of our daily lives. While they bring convenience and innovation, they also contribute to a growing problem – electronic waste or e-waste. Managing this electronic waste responsibly is crucial for the environment and human health. This is where Junk Marina steps in, offering a cutting-edge E-Waste Removal service that not only addresses the problem but also paves the way for a greener future.
                    </p>
                </div>
            </section>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="junk-removal-info-section">
                    <div className="free-junk-info-div">
                        <h1>
                            What causes the <span className="number-design">e-waste</span> crisis?
                        </h1>
                        <p>In today's digital era, electronic devices are indispensable, yet they also generate a significant environmental issue: electronic waste, or e-waste. Proper management of this waste is essential for environmental and human health.</p>
                    </div>
                    <div className="junk-removal-info-img">
                        <img src={img} className="junk-removing-img" alt="e-waste removal" />
                    </div>
                </div>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Junk Marina: The <span className="number-design">Solution</span>
                    </h2>
                    <p>Junk Marina has recognized the pressing need for responsible e-waste disposal and has emerged as a pioneer in this field. Their E-Waste Removal service is a testament to their commitment to sustainable waste management. Here’s what sets them apart:</p>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Comprehensive</dt>
                    <dd>
                        Junk Marina offers a comprehensive solution for e-waste disposal. They collect all types of electronic devices, from old cell phones and computers to refrigerators and televisions, ensuring that no item goes to waste.
                    </dd>
                    <dt>Convenient</dt>
                    <dd>
                        The service includes hassle-free pickup from your doorstep, sparing you the trouble of navigating through recycling centers or landfills.
                    </dd>
                    <dt>Eco-friendly</dt>
                    <dd>
                        Junk Marina places a strong emphasis on eco-friendly disposal methods. They ensure that e-waste is treated and recycled in accordance with strict environmental regulations, minimizing harm to the planet.
                    </dd>
                </dl>
                <dl>
                    <dt>Security</dt>
                    <dd>
                        They prioritize data security, assuring customers that their personal information will be thoroughly wiped from electronic devices before disposal.
                    </dd>
                    <dt>Community</dt>
                    <dd>
                        Junk Marina is actively involved in community awareness campaigns. They educate the public on the importance of responsible e-waste management, fostering a sense of environmental responsibility.
                    </dd>

                    <dt>Compliance</dt>
                    <dd>
                        The company is fully compliant with local and national regulations regarding e-waste disposal. Customers can trust that their waste is being handled legally and ethically.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">

                        What makes <span className="number-design">Junk Marina's</span> disposal methods environmentally friendly?

                    </h2>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Service</dt>
                    <dd>
                        We understand that junk removal needs can be urgent. Junk Marina offers prompt and reliable services to cater to your immediate needs.
                    </dd>
                    <dt>Eco-Friendly</dt>
                    <dd>
                        We are committed to protecting the environment. Our disposal methods prioritize recycling and responsible waste management.
                    </dd>
                    <dt>Pricing</dt>
                    <dd>
                        With Junk Marina, you’ll never face hidden fees or surprises. We provide upfront, transparent pricing for our services.
                    </dd>
                </dl>
                <dl>
                    <dt>Experienced</dt>
                    <dd>
                        Our team is highly trained and experienced, ensuring the safe and efficient removal of all types of junk.
                    </dd>
                    <dt>Satisfaction</dt>
                    <dd>
                        Your satisfaction is our ultimate goal. We go above and beyond to ensure you are delighted with our services.
                    </dd>
                    <dt>Clean-Up</dt>
                    <dd>
                        After debris removal, we conduct a thorough clean-up to ensure your site is left spotless and safe for the next phase of your project.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Get in touch with <span className="number-design">Junk Marina</span> for premium cleanup and adherence services!.
                    </h2>
                    <p className="Service-design">
                        We're ready to help with Junk Removal! Call us or WhatsApp on
                        <a style={{ marginLeft: '10px'}} className="number" href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested" target="_blank">056 5417470</a>
                    </p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default EWaste;
