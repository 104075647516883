import React from 'react';
import './JunkRemovalAll.css'
import img from './images/2289.jpg'
import MyNavbar from "./MyNavbar";
import Footer from './Footer';

const OfficeRemoval = () => {
    return (
        <>
            {/* <head>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" />
            </head> */}
            <MyNavbar/>
            <div className="blog-card">
                <h1>Office Junk Removal</h1>
            </div>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        <span className="number-design">Junk Marina</span> – Your Premier Office
                        Junk Removal Service in the UAE
                    </h2>
                    <p>
                        In the dynamic business scene of the United Arab Emirates (UAE),
                        maintaining productivity requires efficient and dependable services.
                        Office junk removal has emerged as a vital solution amid the evolving
                        needs of growing businesses. Junk Marina stands out in this landscape,
                        providing tailored and reliable office junk removal services to meet
                        the specific demands of UAE businesses.
                    </p>
                </div>
            </section>
            <div className="container" style={{ marginRight: 0, paddingRight: 0 }}>
                <div className="junk-removal-info-section">
                    <div className="free-junk-info-div">
                        <h1>
                            Why Choose <span className="number-design">Junk Marina</span> for Office
                            Junk Removal?
                        </h1>
                    </div>
                    <div className="junk-removal-info-img">
                        <img src={img} className="junk-removing-img" alt="office junk removal" />
                    </div>
                </div>
            </div>
            <section className="dl-blurbs">
                <dl>
                    <dt>Expert</dt>
                    <dd>
                        Junk Marina specializes in office junk removal, making us experts in
                        handling the specific challenges and requirements associated with
                        clearing out corporate spaces. Our team understands the importance of
                        maintaining a clean and organized office, allowing your staff to focus
                        on what they do best.
                    </dd>
                    <dt>Customized</dt>
                    <dd>
                        Every office is different, and so are its junk removal needs. Junk Marina takes pride in offering customized solutions to meet your specific requirements. Whether it’s clearing out old office furniture, electronics, or general clutter, we have the expertise to handle it all.
                    </dd>
                    <dt>Eco-friendly</dt>
                    <dd>
                        We are committed to environmental responsibility. Junk Marina prioritizes eco-friendly disposal methods, ensuring that your office waste is disposed of in an environmentally conscious manner. We aim to minimize our carbon footprint by recycling and reusing materials whenever possible.
                    </dd>
                </dl>
                <dl>
                    <dt>Efficient</dt>
                    <dd>
                        We understand that downtime can be costly for your business. That’s why Junk Marina offers timely and efficient services, working around your schedule to minimize disruption to your operations. Our team is punctual and works swiftly to get the job done.
                    </dd>
                    <dt>Safety</dt>
                    <dd>
                        Safety is paramount in all our operations. We adhere to all safety guidelines and regulations, ensuring that the removal process is not only efficient but also secure. You can trust us to handle your office items with care.
                    </dd>
                    <dt>Pricing</dt>
                    <dd>
                        Junk Marina offers competitive pricing packages that suit your budget. We believe that professional office junk removal should be accessible to all businesses, big and small.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        What makes <span className="number-design">Junk Marina's</span> disposal
                        methods environmentally friendly? Read about our services!.
                    </h2>
                </div>
            </section>
            <section className="dl-blurbs">
                <dl>
                    <dt>Furniture</dt>
                    <dd>
                        Whether you’re upgrading your office furniture or downsizing, we can help you remove old desks, chairs, and cabinets efficiently.
                    </dd>
                    <dt>Recycling</dt>
                    <dd>
                        Disposing of old electronics can be challenging due to environmental concerns. Junk Marina ensures that your electronic waste is properly recycled or disposed of in compliance with regulations.
                    </dd>
                    <dt>Documentation</dt>
                    <dd>
                        Protect your sensitive information with our secure paper and document shredding services, ensuring that confidential data remains confidential.
                    </dd>
                </dl>
                <dl>
                    <dt>Organized</dt>
                    <dd>
                        From old paperwork and office supplies to miscellaneous clutter, we’ll help you clear it all out, leaving your workspace refreshed and organized.
                    </dd>
                    <dt>Satisfaction</dt>
                    <dd>
                        Your satisfaction is our ultimate goal. We go above and beyond to
                        ensure you are delighted with our services.
                    </dd>
                    <dt>Clean-Up</dt>
                    <dd>
                        After debris removal, we conduct a thorough clean-up to ensure your
                        site is left spotless and safe for the next phase of your project.
                    </dd>
                </dl>
            </section>
            <section className="call-us-section">
                <div className="container">
                    <h2 className="contect-num">
                        Reach out to <span className="number-design">Junk Marina</span> for exceptional cleanup and compliance solutions!
                    </h2>
                    <p className="Service-design">
                        We're ready to help with Junk Removal! Call us or WhatsApp on
                        <a style={{ marginLeft: '10px'}} className="number"
                           href="https://wa.me/971565417470?text=Hi%20Junk%20Marina%20I'm%20Interested"
                           target="_blank" rel="noreferrer">056 5417470</a>
                    </p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default OfficeRemoval;
